import React from 'react';
import Plyr from 'plyr-react';

import Details from './Details';
import CoverPhoto from './CoverPhoto';

class AudioPlayer extends React.PureComponent {
  play() {
    if (this.plyr) this.plyr.plyr.play();
  }

  render() {
    if (!this.props.item) return null;
    const item = this.props.item;
    return (
      <div className="p-audio-player">
        <div className="p-inner-container">
          <CoverPhoto item={this.props.item} />
          <Details body item={item} />
        </div>
        <Plyr
          ref={(c) => (this.plyr = c)}
          source={{
            type: 'audio',
            sources: [
              {
                src: item.media_url,
              },
            ],
          }}
        />
      </div>
    );
  }
}

export default AudioPlayer;
