import React from 'react';
import Plyr from 'plyr-react';

import Details from './Details';

class VideoPlayer extends React.PureComponent {
  play() {
    if (this.plyr) this.plyr.plyr.play();
  }

  render() {
    if (!this.props.item) return null;
    const item = this.props.item;
    return (
      <div className="p-video-player">
        <Plyr
          ref={(c) => (this.plyr = c)}
          source={{
            type: 'video',
            sources: [
              {
                src: item.media_url,
              },
            ],
            poster: item.image,
          }}
        />
        <Details body item={item} />
      </div>
    );
  }
}

export default VideoPlayer;
