import React from 'react';

import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';

class BigPlayer extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (
      prevProps.item.id &&
      prevProps.item.id !== this.props.item.id &&
      this.plyr
    ) {
      this.plyr.play();
    }
  }

  getComponent() {
    if (
      this.props.item.enclosure_type &&
      this.props.item.enclosure_type.startsWith('video')
    ) {
      return VideoPlayer;
    } else {
      return AudioPlayer;
    }
  }

  render() {
    if (!this.props.item) return null;
    const PlayerComponent = this.getComponent();
    return (
      <PlayerComponent ref={c => (this.plyr = c)} item={this.props.item} />
    );
  }
}

export default BigPlayer;
