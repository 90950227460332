import React from 'react';
import _i from 'immutable-functions';
import API from '../api';
import BigPlayer from './BigPlayer';
import ListItem from './ListItem';

class Podcast extends React.PureComponent {
  _isMounted = true;

  state = {
    itemsToShow: 10,
    playerItemId: null,
  };

  constructor(props) {
    super(props);

    this.selectItem = this.selectItem.bind(this);
    this.showMore = this.showMore.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (
      !(
        this.props.item &&
        this.props.item.settings &&
        this.props.item.settings.player &&
        this.props.item.settings.player.podcast_uuid
      )
    )
      return;

    const podcast_uuid = this.props.item.settings.player.podcast_uuid;
    _.delay(() => {
      if (!this._isMounted) return;
      this.apiCall = API.getPodcast(
        this.props.protocol,
        this.props.host,
        podcast_uuid
      ).then(
        podcast => {
          if (this._isMounted)
            this.setState({
              podcast,
              playerItemId: (podcast.items[0] || {}).id,
            });
        },
        e => console.log('API Error', e)
      );
    }, 50);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  selectItem(playerItemId) {
    this.setState({ playerItemId });
    this.scrollToPlayer();
  }

  showMore() {
    this.setState({ itemsToShow: this.state.itemsToShow + 10 });
  }

  scrollToPlayer() {
    const breather = 20;
    const offset = $('.p-player-container').offset();
    let position = offset.top - breather;

    if ($('.section.header').css('position') === 'fixed') {
      let headerHeight = $('.section.header').outerHeight();
      if (headerHeight > 0) position -= headerHeight;
    }

    position = Math.max(0, position);

    if (position) $('html, body').animate({ scrollTop: position }, 500);
  }

  render() {
    if (!this.state.podcast || !this.state.playerItemId) return null;
    const playerItem = _i.find(
      this.state.podcast.items,
      this.state.playerItemId || this.state.podcast.items[0].id
    );
    return (
      <div className="p-player-container">
        <div className="p-player-main">
          <BigPlayer item={playerItem} />
        </div>
        <div className="p-past-episodes">
          <div className="p-more-from-label">
            More from {this.state.podcast.title}
          </div>
          {this.state.podcast.items
            .filter(i => i.id !== this.state.playerItemId.id)
            .slice(0, this.state.itemsToShow)
            .map(item => (
              <ListItem item={item} key={item.id} onPress={this.selectItem} />
            ))}
          {this.state.podcast.items.length > this.state.itemsToShow && (
            <div className="p-show-more-container">
              <button className="button" onClick={this.showMore}>
                Show More
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Podcast;
